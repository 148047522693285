/* Built In Imports */

/* External Imports */
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 * Renders the should Refresh Component
 *
 * @param {string} url - Link
 * @returns {ReactElement} - should Refresh Component
 */

export const getCookie = name => {
  if (typeof window !== 'undefined') {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
};
let domainList = [
  '//prs.innerengineering.com',
  '//prs.isha.in',
  '//sadhana.sadhguru.org',
  'mokshatwo.sushumna.isha.in',
  'goyip.netlify.app',
  'ishangam.isha.in',
  'msrsp.sadhguru.org',
  'ishaoutreach.org',
  'iycblr.sadhguru.org',
  'sp.isha.in',
  'uat-facade-sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
  'sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
  'ishaprs.sushumna.isha.in',
  'online.sadhguru.org',
  'online2.sadhguru.org',
  'registration.innerengineering.com',
  'qa-prs8.innerengineering.com',
  'lingabhairavi.org',
  'beta.lingabhairavi.org',
  'hys.sushumna.isha.in',
  'hys.isha.in',
  'isha.co',
  'poornanga.sushumna.isha.in',
  'poornanga.isha.in',
  'preprod-online.sadhguru.org',
  'uat-arpanam.sadhguru.org',
  'arpanam.sadhguru.org',
  'uat-lbofferings.sadhguru.org',
  'uat-arpanam.sadhguru.org',
  'iso-public-upload.s3.ap-south-1.amazonaws.com',
  'admissions.ishahomeschool.org',
  'static.sadhguru.org/d/46272',
];
export const isOpenInNewTab = url => {
  let isUrlAvailableForUtmParam = [];
  if (
    isUrlAvailableForUtmParam?.indexOf('http') > -1 &&
    url?.indexOf(config.cdnPath) < 0
  ) {
    isUrlAvailableForUtmParam = [url];
  } else {
    isUrlAvailableForUtmParam = filter(domainList, function (domain) {
      return url?.indexOf(domain) > -1;
    });
  }
  return isUrlAvailableForUtmParam?.length > 0;
};

export const handleUtmParams = (url, extraDomains) => {
  let domainList = [
    '//prs.innerengineering.com',
    '//prs.isha.in',
    '//sadhana.sadhguru.org',
    'mokshatwo.sushumna.isha.in',
    'goyip.netlify.app',
    'ishangam.isha.in',
    'msrsp.sadhguru.org',
    'ishaoutreach.org',
    'iycblr.sadhguru.org',
    'sp.isha.in',
    'uat-facade-sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
    'sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
    'ishaprs.sushumna.isha.in',
    'online.sadhguru.org',
    'online2.sadhguru.org',
    'registration.innerengineering.com',
    'qa-prs8.innerengineering.com',
    'lingabhairavi.org',
    'beta.lingabhairavi.org',
    'hys.sushumna.isha.in',
    'hys.isha.in',
    'isha.co',
    'poornanga.sushumna.isha.in',
    'poornanga.isha.in',
    'preprod-online.sadhguru.org',
    'uat-arpanam.sadhguru.org',
    'arpanam.sadhguru.org',
    'uat-lbofferings.sadhguru.org',
    'uat-arpanam.sadhguru.org',
    'admissions.ishahomeschool.org',
  ];

  if (extraDomains?.length) {
    domainList = domainList.concat(extraDomains);
  }

  const cookieParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  let newUrl = '';

  const isUrlAvailableForUtmParam = filter(domainList, function (domain) {
    return url?.indexOf(domain) > -1;
  });

  if (isUrlAvailableForUtmParam.length) {
    const nUrl = new URL(url);
    const search_params = new URLSearchParams(nUrl.search);

    forEach(cookieParams, function (cookie) {
      const cookieVal = getCookie(cookie);

      if (cookieVal) {
        search_params.set(cookie, cookieVal);
      }
    });
    // change the search property of the main url
    nUrl.search = search_params.toString();

    // the new url string
    newUrl = nUrl.toString();
  } else {
    newUrl = url;
  }
  return newUrl;
};

export const refineUrlDomain = (url, region, lang, extraDomains) => {
  let paramUrl = url;
  if (url?.indexOf('[envurl') > -1) {
    const updatedUrlArr = url
      .replace('][/envurl]', '')
      ?.replace('[envurl ', '')
      ?.split(' ');
    forEach(updatedUrlArr, function (upUrl) {
      if (config.ENV_NAME === 'production') {
        if (upUrl?.indexOf('prod=') > -1) {
          paramUrl = upUrl.replace("prod='", '').replace("'", '');
        }
      } else if (upUrl?.indexOf('uat=') > -1) {
        paramUrl = upUrl.replace("uat='", '').replace("'", '');
      }
    });
  }

  let finalUrl = '';

  if (!paramUrl) {
    finalUrl = '#!';
  } else if (
    (paramUrl && paramUrl[0] === '#') ||
    paramUrl?.toLowerCase().indexOf('https://') >= 0 ||
    paramUrl?.toLowerCase().indexOf('http://') >= 0 ||
    paramUrl?.toLowerCase().indexOf('mailto:') >= 0
  ) {
    finalUrl = paramUrl;
  } else if (paramUrl?.indexOf('isha-home-school/') > -1) {
    finalUrl = paramUrl;
  } else {
    finalUrl = `/isha-home-school${paramUrl[0] === '/' ? '' : '/'}${paramUrl}`;
  }
  if (finalUrl.endsWith('/')) {
    finalUrl = finalUrl.slice(0, -1);
  }
  finalUrl = handleUtmParams(finalUrl, extraDomains);
  return finalUrl;
};

export const getEmbedUrl = url => {
  let retUrl = '';
  if (!url) {
    return retUrl;
  }
  if (url.indexOf('/watch') > -1) {
    retUrl = url.split('=')[1];
  } else if (url.indexOf('/embed/') > -1 || url.indexOf('/youtu.be/') > -1) {
    const splitArr = url.split('/');
    const splittedUrl = splitArr[splitArr.length - 1];
    retUrl =
      splittedUrl.split('?').length > 1
        ? splittedUrl.split('?')[0]
        : splittedUrl;
  } else {
    retUrl = url;
  }
  return retUrl;
};
export const setAudioPlayer = (audioUrl, tag) => {
  let audioPlayer = document.querySelector('.audio-player'); // from poem
  if (tag) {
    // from audio or power press / from html parser
    audioPlayer = document.querySelector(`.powerpress`);
  }
  const audio = new Audio(audioUrl);

  //   It loads and play the audio file on click of play button for the first time. Because of Closure and IIFE function.
  const playAudio = (function () {
    let executed = false;
    return function () {
      if (!executed && !tag) {
        executed = true;
        const playBtn =
          audioPlayer && audioPlayer.querySelector('.controls .toggle-play');
        playBtn.classList.remove('play');
        playBtn.classList.add('pause');
        audio.play();
      }
    };
  })();

  playAudio();

  // console.dir(audio);

  audio.addEventListener(
    'loadeddata',
    () => {
      audioPlayer.querySelector(
        '.time .length'
      ).textContent = `/ ${getTimeCodeFromNum(audio.duration)} `;
      audio.volume = 0.75;
    },
    false
  );

  // click on timeline to skip around
  const timeline = audioPlayer && audioPlayer.querySelector('.timeline');
  timeline &&
    timeline.addEventListener(
      'click',
      e => {
        const timelineWidth = window.getComputedStyle(timeline).width;
        const timeToSeek =
          (e.offsetX / parseInt(timelineWidth, 10)) * audio.duration;
        audio.currentTime = timeToSeek;
      },
      false
    );

  // click volume slider to change volume
  const volumeSlider =
    audioPlayer && audioPlayer.querySelector('.controls .volume-slider');
  volumeSlider &&
    volumeSlider.addEventListener(
      'click',
      e => {
        const sliderWidth = window.getComputedStyle(volumeSlider).width;
        const newVolume = e.offsetX / parseInt(sliderWidth, 10);
        audio.volume = newVolume;
        audioPlayer.querySelector(
          '.controls .volume-percentage'
        ).style.width = `${newVolume * 100}%`;
      },
      false
    );

  // check audio percentage and update time accordingly
  setInterval(() => {
    const progressBar = audioPlayer && audioPlayer.querySelector('.progress');
    if (progressBar) {
      progressBar.style.width = `${
        (audio.currentTime / audio.duration) * 100
      }%`;
    }
    if (audioPlayer) {
      audioPlayer.querySelector('.time .current').textContent =
        getTimeCodeFromNum(audio.currentTime);
    }
  }, 500);

  // toggle between playing and pausing on button click
  const playBtn =
    audioPlayer && audioPlayer.querySelector('.controls .toggle-play');

  playBtn &&
    playBtn.addEventListener(
      'click',
      () => {
        if (audio.paused) {
          playBtn.classList.remove('play');
          playBtn.classList.add('pause');
          audio.play();
        } else {
          playBtn.classList.remove('pause');
          playBtn.classList.add('play');
          audio.pause();
        }
      },
      false
    );

  audioPlayer &&
    audioPlayer
      .querySelector('.volume-button')
      .addEventListener('click', () => {
        const volumeEl = audioPlayer.querySelector('.volume-container .volume');
        audio.muted = !audio.muted;
        if (audio.muted) {
          volumeEl.classList.remove('icono-volumeMedium');
          volumeEl.classList.add('icono-volumeMute');
        } else {
          volumeEl.classList.add('icono-volumeMedium');
          volumeEl.classList.remove('icono-volumeMute');
        }
      });

  // turn 128 seconds into 2:08
  /**
   *
   * @param num
   */
  function getTimeCodeFromNum(num) {
    let seconds = parseInt(num, 10);
    let minutes = parseInt(seconds / 60, 10);
    seconds -= minutes * 60;
    const hours = parseInt(minutes / 60, 10);
    minutes -= hours * 60;

    if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
    return `${String(hours).padStart(2, 0)}:${minutes}:${String(
      seconds % 60
    ).padStart(2, 0)}`;
  }
};

const toDataURL = async url => {
  const blob = await fetch(url).then(res => res.blob());
  return URL.createObjectURL(blob);
};

export const downloadUrl = async (url, name) => {
  const a = document.createElement('a');
  a.href = await toDataURL(url);
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
