/* Built In Imports */
// import NextLink from 'next/link';

/* External Imports */
import {
  Box,
  Flex,
  Heading,
  Link,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { BsInstagram, BsLinkedin } from 'react-icons/bs';

/* Internal Imports */
/* Components */
import FooterLogo from '@ihs/components/Icons/FooterLogo';
import { isOpenInNewTab, refineUrlDomain } from '@ihs/components/Utility/utils';

/**
 *
 * @param {Object} footerData
 * @returns {ReactElement} Footer component
 */
const footer = ({ footerData }) => {
  const addTo = footerData?.allIsoMainMenus?.[0]?.submenuItems;
  const usefulLinkItems =
    footerData?.allIsoMainMenus?.[0]?.submenuItems?.[4]?.submenuItems;
  const exploreItems =
    footerData?.allIsoMainMenus?.[0]?.submenuItems?.[5]?.submenuItems;
  const getInTouchItems =
    footerData?.allIsoMainMenus?.[0]?.submenuItems?.[6]?.submenuItems;
  // console.log('footerData', addTo);
  return (
    <Box
      className="footer"
      // maxW="1330px"
      w="100%"
      mx="auto"
      backgroundColor="#002033"
      as="footer"
      h="100%"
      pb="12px"
      fontWeight="400"
    >
      <Box
        objectFit="cover"
        w="100%"
        alignItems="center"
        justifyItems="center"
        h="auto"
        mx={{ base: '0', md: 'auto' }}
        px={{ base: '39px', md: '0' }}
        pt={{ base: '39px', xl: '63px' }}
        maxW={{ base: '348px', md: '720px', lg: '960px', xl: '1030px' }}
      >
        <Flex>
          <Box
            display="flex"
            flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
            justifyContent={{ base: 'center', lg: 'space-between' }}
            mx={{ base: 'auto' }}
            w="100%"
            gridGap={{ base: '0px', md: '20px', xl: '120px' }}
            color="white"
          >
            <Box
              // mx={{ base: "auto" }}
              display={{ base: 'none', md: 'flex', xl: 'block' }}
              justifyContent={{
                base: 'center',
                md: 'flex-start',
                xl: 'flex-start',
              }}
              flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
              mb={{ base: '40px', xl: '0' }}
            >
              <Box w="73px" h="60px">
                {/* <NextLink
                  href={refineUrlDomain('/')}
                  passHref
                  legacyBehavior
                > */}
                <Link
                  href={refineUrlDomain('/')}
                  _focus={{
                    textDecoration: 'none',
                    color: '#c85333',
                    boxShadow: 'none',
                  }}
                >
                  <FooterLogo />
                </Link>
                {/* </NextLink> */}
              </Box>
              <Box>
                <Text
                  w="200px"
                  fontSize="12px"
                  fontFamily="Zilla Slab"
                  fontWeight="400"
                  lineHeight="19px"
                  color="#94A3B8"
                  mt="22px"
                >
                  {`${addTo?.[3]?.title} | `}
                  {/* <NextLink
                    href={refineUrlDomain(addTo?.[0]?.url)}
                    passHref
                    legacyBehavior
                  > */}
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#FBAD18',
                      boxShadow: 'none',
                    }}
                    href={refineUrlDomain(addTo?.[0]?.url)}
                    target={isOpenInNewTab(addTo?.[0]?.url) ? '_blank' : ''}
                    _focus={{
                      textDecoration: 'none',
                      color: '#FBAD18',
                      boxShadow: 'none',
                    }}
                  >
                    {` ${addTo?.[0]?.title} | `}
                  </Link>
                  {/* </NextLink> */}
                  {/* <NextLink
                    href={refineUrlDomain(addTo?.[1]?.url)}
                    passHref
                    legacyBehavior
                  > */}
                  <Link
                    _hover={{
                      textDecoration: 'none',
                      color: '#FBAD18',
                      boxShadow: 'none',
                    }}
                    href={refineUrlDomain(addTo?.[1]?.url)}
                    target={isOpenInNewTab(addTo?.[1]?.url) ? '_blank' : ''}
                    _focus={{
                      textDecoration: 'none',
                      color: '#FBAD18',
                      boxShadow: 'none',
                    }}
                  >
                    {`${addTo?.[1]?.title} `}
                  </Link>
                  {/* </NextLink> */}
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="column"
                maxW="146px"
                w="100%"
                // w={{base:"126px",md:"146px",xl:"246px"}} h="104px"
              >
                <Box>
                  <Heading
                    as="h5"
                    fontWeight="500"
                    lineHeight="20px"
                    fontSize="16px"
                    color="#FBAD18"
                    fontFamily="Zilla Slab"
                  >
                    {addTo?.[4]?.title}
                  </Heading>

                  <List
                    fontSize="16px"
                    mt="20px"
                    mb={{ base: '30px', md: '0' }}
                    color="#fff"
                    fontWeight="400"
                    lineHeight="32px"
                    fontFamily="Zilla Slab"
                  >
                    {/* <NextLink
                      href={refineUrlDomain(usefulLinkItems?.[0].url)}
                      legacyBehavior
                      passHref
                    > */}
                    <Link
                      href={refineUrlDomain(usefulLinkItems?.[0].url)}
                      target={
                        isOpenInNewTab(usefulLinkItems?.[0].url) ? '_blank' : ''
                      }
                      _hover={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                    >
                      <ListItem>{usefulLinkItems?.[0]?.title}</ListItem>
                    </Link>
                    {/* </NextLink>
                    <NextLink
                      legacyBehavior
                      href={refineUrlDomain(usefulLinkItems?.[1].url)}
                      passHref
                    > */}
                    <Link
                      _hover={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      href={refineUrlDomain(usefulLinkItems?.[1].url)}
                      target={
                        isOpenInNewTab(usefulLinkItems?.[1].url) ? '_blank' : ''
                      }
                    >
                      <ListItem>{usefulLinkItems?.[1].title}</ListItem>
                    </Link>
                    {/* </NextLink> */}
                  </List>
                </Box>
              </Box>
              <Box
                w={{ base: '152px', md: '252px' }}
                m={{ base: '0px 0 0 0px', xl: '0px 0px 0px 52px' }}
                //   h="168px"
                display="flex"
                maxW="252px"
                // w="100%"
                flexDirection="column"
              >
                <Box>
                  <Heading
                    as="h5"
                    fontSize="16px"
                    lineHeight="20px"
                    fontWeight="500"
                    color="#FBAD18"
                    fontFamily="Zilla Slab"
                  >
                    {addTo?.[5].title}
                  </Heading>
                  <List
                    lineHeight="32px"
                    fontWeight="400"
                    fontFamily="Zilla Slab"
                    fontSize="16px"
                    color="#fff"
                    mt="20px"
                    mb={{ base: '30px', md: '0' }}
                  >
                    {/* <NextLink
                      legacyBehavior
                      href={refineUrlDomain(exploreItems?.[0].url)}
                      passHref
                    > */}
                    <Link
                      _hover={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      href={refineUrlDomain(exploreItems?.[0].url)}
                      target={
                        isOpenInNewTab(exploreItems?.[0].url) ? '_blank' : ''
                      }
                    >
                      <ListItem>{exploreItems?.[0].title}</ListItem>
                    </Link>
                    {/* </NextLink>
                    <NextLink
                      legacyBehavior
                      passHref
                      href={refineUrlDomain(exploreItems?.[1].url)}
                    > */}
                    <Link
                      href={refineUrlDomain(exploreItems?.[1].url)}
                      target={
                        isOpenInNewTab(exploreItems?.[1].url) ? '_blank' : ''
                      }
                      _hover={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                    >
                      <ListItem>{exploreItems?.[1].title}</ListItem>
                    </Link>
                    {/* </NextLink>
                    <NextLink
                      href={refineUrlDomain(exploreItems?.[2].url)}
                      legacyBehavior
                      passHref
                    > */}
                    <Link
                      href={refineUrlDomain(exploreItems?.[2].url)}
                      target={
                        isOpenInNewTab(exploreItems?.[2].url) ? '_blank' : ''
                      }
                      _hover={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                    >
                      <ListItem>{exploreItems?.[2].title} </ListItem>
                    </Link>
                    {/* </NextLink>
                    <NextLink
                      legacyBehavior
                      passHref
                      href={refineUrlDomain(exploreItems?.[3].url)}
                    > */}
                    {/* {exploreItems[3]?.url && (
                      <Link
                        href={refineUrlDomain(exploreItems?.[3]?.url)}
                        _hover={{
                          textDecoration: 'none',
                          color: '#FBAD18',
                          boxShadow: "none"
                        }}
                        _focus={{
                          textDecoration: 'none',
                          color: '#FBAD18',
                          boxShadow: "none"
                        }}
                      >
                        <ListItem>{exploreItems?.[3].title}</ListItem>
                      </Link>
                    )} */}
                    {/* </NextLink> */}
                  </List>
                </Box>
              </Box>
              <Box
                w={{ base: 'auto', md: '242px' }}
                h="auto"
                display="flex"
                flexDirection="column"
              >
                <Box>
                  <Heading
                    as="h5"
                    fontSize="16px"
                    fontFamily="Zilla Slab"
                    lineHeight="20px"
                    fontWeight="500"
                    color="#FBAD18"
                  >
                    {addTo?.[6].title}
                  </Heading>

                  <List
                    mt="20px"
                    color="#fff"
                    mb={{ base: '30px', md: '0' }}
                    fontFamily="Zilla Slab"
                    lineHeight="22px"
                  >
                    <ListItem>{getInTouchItems?.[0].title}</ListItem>
                    <Link
                      _hover={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      href={`mailto:${getInTouchItems?.[0].url}`}
                    >
                      <ListItem mt="10px">{getInTouchItems?.[0].key}</ListItem>
                    </Link>
                    <ListItem mt="25px">{getInTouchItems?.[1].title}</ListItem>
                    <Link
                      _hover={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      href={`mailto:${getInTouchItems?.[1].url}`}
                    >
                      <ListItem mt="10px">{getInTouchItems?.[1].key}</ListItem>
                    </Link>
                  </List>
                  <Flex
                    gridColumnGap={{ base: '21px', md: '18px' }}
                    display={{ base: 'flex', md: 'flex' }}
                    fontSize="22px"
                    color="#475569"
                    mt="32px"
                  >
                    {/* <NextLink
                      legacyBehavior
                      passHref
                      href="https://www.instagram.com/ishahomeschool/"

                    > */}
                    <Link
                      href="https://www.instagram.com/ishahomeschool/"
                      target={
                        isOpenInNewTab(
                          'https://www.instagram.com/ishahomeschool/'
                        )
                          ? '_blank'
                          : ''
                      }
                      _hover={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      // mr="21px"
                    >
                      <BsInstagram />
                    </Link>
                    {/* </NextLink>
                    <NextLink
                      legacyBehavior
                      passHref
                      href="https://www.linkedin.com/school/ishahomeschool/"
                    > */}
                    <Link
                      href="https://www.linkedin.com/school/ishahomeschool/"
                      target={
                        isOpenInNewTab(
                          'https://www.linkedin.com/school/ishahomeschool/'
                        )
                          ? '_blank'
                          : ''
                      }
                      _hover={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        textDecoration: 'none',
                        color: '#FBAD18',
                        boxShadow: 'none',
                      }}
                    >
                      <BsLinkedin />
                    </Link>
                    {/* </NextLink> */}
                  </Flex>
                </Box>
              </Box>
            </Box>
            <Box flexDirection="column" display={{ md: 'none', base: 'block' }}>
              <Box
                w="73px"
                h="60px"
                m={{ xl: '0px 0px 0px 152px', base: '39px 0 0 0px' }}
              >
                {/* <NextLink
                  href={refineUrlDomain('/')}
                  legacyBehavior
                  passHref
                > */}
                <Link
                  href={refineUrlDomain('/')}
                  _focus={{
                    textDecoration: 'none',
                    color: '#c85333',
                    boxShadow: 'none',
                  }}
                >
                  <FooterLogo />
                </Link>
                {/* </NextLink> */}
              </Box>
              <Box
                w="100%"
                h="38px"
                fontSize="12px"
                fontFamily="Zilla Slab"
                fontWeight="400"
                lineHeight="19px"
                color="#94A3B8"
                ml={{ sm: '0px', base: '0px', md: '152px' }}
                mt="22px"
              >
                <Box>
                  <List>
                    <ListItem>
                      {
                        footerData?.allIsoMainMenus?.[0]?.submenuItems?.[3]
                          .title
                      }{' '}
                      |
                    </ListItem>
                    <Flex direction="row">
                      {/* <NextLink
                        href={refineUrlDomain(footerData?.allIsoMainMenus?.[0]?.submenuItems?.[0].url)}
                        legacyBehavior
                        passHref
                      > */}
                      <Link
                        href={refineUrlDomain(
                          footerData?.allIsoMainMenus?.[0]?.submenuItems?.[0]
                            .url
                        )}
                        target={
                          isOpenInNewTab(
                            footerData?.allIsoMainMenus?.[0]?.submenuItems?.[0]
                              .url
                          )
                            ? '_blank'
                            : ''
                        }
                        _hover={{
                          textDecoration: 'none',
                          color: '#FBAD18',
                          boxShadow: 'none',
                        }}
                        _focus={{
                          textDecoration: 'none',
                          color: '#FBAD18',
                          boxShadow: 'none',
                        }}
                      >
                        <ListItem pr="5px">
                          {' '}
                          {
                            footerData?.allIsoMainMenus?.[0]?.submenuItems?.[0]
                              ?.title
                          }
                        </ListItem>
                      </Link>
                      {/* </NextLink> */}
                      <ListItem>|</ListItem>
                      {/* <NextLink
                        href={refineUrlDomain(footerData?.allIsoMainMenus?.[0]?.submenuItems?.[1].url)}
                        legacyBehavior
                        passHref
                      > */}
                      <Link
                        _hover={{
                          textDecoration: 'none',
                          color: '#FBAD18',
                          boxShadow: 'none',
                        }}
                        href={refineUrlDomain(
                          footerData?.allIsoMainMenus?.[0]?.submenuItems?.[1]
                            .url
                        )}
                        target={
                          isOpenInNewTab(
                            footerData?.allIsoMainMenus?.[0]?.submenuItems?.[1]
                              .url
                          )
                            ? '_blank'
                            : ''
                        }
                        _focus={{
                          textDecoration: 'none',
                          color: '#FBAD18',
                          boxShadow: 'none',
                        }}
                      >
                        <ListItem pl="5px">
                          {' '}
                          {
                            footerData?.allIsoMainMenus?.[0]?.submenuItems?.[1]
                              ?.title
                          }
                        </ListItem>
                      </Link>
                      {/* </NextLink> */}
                    </Flex>
                  </List>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box
        maxW="1330px"
        mx="auto"
        mt={{ base: '42px', md: '32px', lg: '113px' }}
        // ml={{ base: '0px', md: '0', xl: 'auto' }}
        bgSize={{ base: 'cover', md: '100%' }}
        h={{ base: '40px', lg: '62px' }}
        w="100%"
        bgPos="center"
        bgRepeat="no-repeat"
        bgImage="/assets/images/IHSFooterBorder.png"
        // pb="18.5px"
        // mb="12px"
      />
    </Box>
  );
};

export default footer;
