/* Built In Imports */
// import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { CloseIcon } from '@chakra-ui/icons';
import { Accordion, Box, Flex, Link, Text, chakra } from '@chakra-ui/react';
import HeaderStyle from '@ihs/components/Navigation/HeaderStyle';
import { nanoid } from 'nanoid';
import { BiRightArrowAlt } from 'react-icons/bi';
import { GiHamburgerMenu } from 'react-icons/gi';

/* Internal Imports */
/* Components */
import Logo, { SmLogo } from '@ihs/components/Icons/Logo';
import {
  HeaderAccItem,
  LinkWChild,
  MenuList,
} from '@ihs/components/Navigation/LinkComponent';
import { isOpenInNewTab, refineUrlDomain } from '@ihs/components/Utility/utils';

/**
 * @param {Object} HeaderMenuData
 * @returns {ReactElement} Header component
 */
const Header = ({ headerMenuData }) => {
  const ihsSlug = 'isha-home-school';
  // console.log(headerMenuData, "headermenu data")
  const router = useRouter();
  const query = router.query.levelOne;
  const [urlMatch, seturlMatch] = useState(null);
  const RightArrow = chakra(BiRightArrowAlt);
  const [menuIndex, setMenuIndex] = useState(-1);
  const [mtoggle, setMtoggle] = useState(false);
  const HamburgerIcon = chakra(GiHamburgerMenu);
  const ref2 = useRef();

  useEffect(() => {
    headerMenuData?.allIsoMainMenus?.map(menu => {
      if (menu.key === query) {
        seturlMatch([menu.key]);
      } else if (menu.key === '' || menu.title.toLowerCase() === 'home') {
        seturlMatch(null);
      }
      const matchedUrl = menu?.submenuItems?.filter(subMenu => {
        const matched = subMenu?.key === query;
        if (subMenu?.key === query) {
          seturlMatch([menu?.key]);
        }
        return matched;
      });
      return matchedUrl;
    });
  }, [query]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setMtoggle(false);
    });
  }, []);

  useEffect(() => {
    if (mtoggle) {
      ref2.current.focus();
    }
  }, [mtoggle]);

  const headerHeight = { base: '79px', md: '106px', xl: '118px' };
  const absoluteHeight = {
    base: `calc(100dvh - ${headerHeight.base})`,
    md: `calc(100dvh - ${headerHeight.md})`,
    xl: `calc(100dvh - ${headerHeight.xl})`,
  };

  return (
    <Box
      height="100%"
      w="100%"
      // maxW={1330}
      mx="auto"
      pos={mtoggle ? 'fixed' : 'relative'}
      as="header"
      zIndex="30"
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h={headerHeight}
        borderBottom="2px solid #FBAD18"
        background="#FFF7E1"
        justifyContent="center"
      >
        <Flex
          className="desktop"
          maxW={1330}
          mx="auto"
          w="100%"
          px="50px"
          h="100%"
          alignItems="center"
          flexDir="column"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            mx="auto"
            pt="4px"
            pl="10px"
            px="16px"
            w="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              {/* <NextLink
                href="/isha-home-school"
                passHref
                legacyBehavior> */}
              <Link
                href={`/${ihsSlug}`}
                _focus={{ boxShadow: 'none' }}
                target={isOpenInNewTab(`'/${ihsSlug}'`) ? '_blank' : ''}
              >
                <Logo />
              </Link>
              {/* </NextLink> */}
            </Box>
            <Box
              direction="row"
              mr="0px"
              display="flex"
              justifyItems="center"
              gridGap={{ md: '18px', lg: '28px' }}
              color="rgba(0, 0, 0, 0.80)"
              fontFamily="'Zilla Slab', serif"
              fontSize="18px"
              lineHeight={{ md: '21.65px', base: '17.5px' }}
            >
              <Box>
                <Box display="flex" pt="15px" transition="padding-right 0.3s">
                  <Flex
                    flexDirection="row"
                    border="none"
                    justifyContent="space-between"
                  >
                    <Box pr="27px"></Box>
                    <MenuList
                      urlMatch={{ ...urlMatch }}
                      menuIndex={menuIndex}
                      menuList={headerMenuData?.allIsoMainMenus}
                      setMenuIndex={setMenuIndex}
                    />
                  </Flex>
                </Box>
              </Box>
            </Box>
            {headerMenuData?.allIsoMainMenus.map((navItem, key) => {
              if (navItem.key === 'admission-portal') {
                return (
                  <Flex
                    gridColumnGap="27px"
                    direction="row"
                    mr="0px"
                    justifyItems="center"
                    key={key}
                  >
                    {/* <NextLink
                      href={refineUrlDomain(navItem.url)}
                      target={navItem.url.indexOf('https') > -1 ? "_blank" : ""}
                      // target="_blank"
                      legacyBehavior
                      passHref
                    > */}
                    <Link
                      color="#622210"
                      href={refineUrlDomain(navItem.url)}
                      variant="outline"
                      value="Admissions Portal"
                      p="13px 19px"
                      // rightIcon={}
                      border="none"
                      isExternal={navItem.url?.indexOf('https') > -1}
                      display="flex"
                      outline="2px solid #622210"
                      _hover={{
                        bg: '#622210',
                        boxShadow: 'none',
                        color: '#fff',
                      }}
                      _focus={{
                        bg: '#622210',
                        boxShadow: 'none',
                        color: '#fff',
                      }}
                      _active={{
                        bg: '#622210',
                        boxShadow: 'none',
                        color: '#fff',
                      }}
                      fontWeight={500}
                      alignItems="center"
                    >
                      {navItem.title} <RightArrow fontSize="20px" />
                    </Link>
                    {/* </NextLink> */}
                  </Flex>
                );
              }
            })}
          </Box>
        </Flex>

        <Flex
          // pt={{ base: '10px', md: '20px' }}
          className="mobile"
          px="20px"
          w="100%"
          alignSelf="center"
        >
          <Box
            display="flex"
            maxW={1200}
            flexDirection="row"
            mx="auto"
            w="100%"
            alignItems="center"
            h="auto"
            justifyContent="space-between"
          >
            <Box mt="10px" h="auto" w="auto">
              {/* <NextLink href="/isha-home-school" passHref legacyBehavior> */}
              <Link _focus={{ boxShadow: 'none' }} href={`/${ihsSlug}`}>
                <SmLogo />
              </Link>
              {/* </NextLink> */}
            </Box>

            <Box h="auto" w="auto" px="15px">
              {!mtoggle ? (
                <HamburgerIcon
                  width={{ base: '20px', md: '20px', xl: '25px' }}
                  height={{ base: '20px', md: '20px', xl: '25px' }}
                  //mt="10px"
                  color="#622210"
                  onClick={() => setMtoggle(true)}
                />
              ) : (
                <CloseIcon
                  width={{ base: '15px', md: '18px', xl: '25px' }}
                  height={{ base: '15px', md: '18px', xl: '25px' }}
                  onClick={() => {
                    setMtoggle(false);
                  }}
                  cursor="pointer"
                  color="#622210"
                />
              )}
            </Box>
          </Box>
          <Box w="100%">
            {mtoggle && (
              <Box
                ref={ref2}
                tabIndex={0}
                maxW="100%"
                minW="300px"
                w="100%"
                background="#fff"
                h={absoluteHeight}
                maxH="auto"
                color="#3B444B"
                className="100vh"
                position="fixed"
                top={headerHeight}
                right="0"
                opacity="1.0"
                transition="right 1.3s"
                fontFamily="'Zilla Slab', serif"
              >
                <Box
                  w="100%"
                  overflowY="auto"
                  h={{ base: '80vh', md: '75vh', lg: '90vh' }}
                  top="0"
                  bottom="0"
                  pb="30px"
                >
                  {headerMenuData?.allIsoMainMenus?.map((navItem, key) => {
                    const indexNotToShow = [5, 6, 7];
                    if (
                      !navItem.key ||
                      indexNotToShow.indexOf(key) > -1 ||
                      navItem.key === 'admission-portal'
                    ) {
                      return null;
                    } else if (navItem?.submenuItems?.length) {
                      return (
                        <Accordion
                          allowToggle="true"
                          defaultIndex={
                            urlMatch?.[0] === navItem?.title.toLowerCase()
                              ? 0
                              : null
                          }
                          // index={0}
                          key={navItem?.key || nanoid()}
                          border="none"
                        >
                          <HeaderAccItem
                            fontWeight={
                              router?.asPath === `${ihsSlug}${navItem?.url}` ||
                                urlMatch?.[0] === navItem?.title.toLowerCase()
                                ? '700'
                                : '400'
                            }
                            color={
                              router?.asPath === `${ihsSlug}${navItem?.url}` ||
                                urlMatch?.[0] === navItem?.title.toLowerCase()
                                ? '#812C12'
                                : '#3B444B'
                            }
                            navItem={navItem}
                          />
                        </Accordion>
                      );
                    }
                    return (
                      <LinkWChild
                        key={navItem?.key || nanoid()}
                        href={refineUrlDomain(navItem?.url)}
                      >
                        <Text
                          my="24px"
                          border="none"
                          pl="30px"
                          fontSize="20px"
                          lineHeight="20px"
                          fontWeight={
                            router?.asPath === `${ihsSlug}${navItem?.url}` ||
                              urlMatch?.[0] === navItem?.title.toLowerCase()
                              ? '700'
                              : '400'
                          }
                          color={
                            router?.asPath === `${ihsSlug}${navItem?.url}` ||
                              urlMatch?.[0] === navItem?.title.toLowerCase()
                              ? '#812C12'
                              : '#3B444B'
                          }
                          _hover={{ fontWeight: 700, color: '#812C12' }}
                        >
                          {navItem?.title}
                        </Text>
                      </LinkWChild>
                    );
                  })}
                  {headerMenuData?.allIsoMainMenus?.map((navItem, key) => {
                    if (navItem.key === 'admission-portal') {
                      return (
                        <Flex
                          key={key}
                          gridColumnGap="27px"
                          direction="row"
                          mt={'25px'}
                          mr="0px"
                          display="flex"
                          justifyItems="center"
                          alignItems="center"
                          w="100%"
                          mx="auto"
                        >
                          {/* <NextLink href={refineUrlDomain(navItem.url)} target={navItem.url.indexOf('https') > -1 ? "_blank" : ""} legacyBehavior passHref> */}
                          <Link
                            color="#622210"
                            variant="outline"
                            value="Admissions Portal"
                            p="13px 19px"
                            display="flex"
                            href={refineUrlDomain(navItem.url)}
                            alignItems="center"
                            isExternal={navItem.url.indexOf('https') > -1}
                            border="none"
                            outline="2px solid #622210"
                            _hover={{
                              bg: '#622210',
                              boxShadow: 'none',
                              color: '#fff',
                            }}
                            _focus={{
                              bg: '#622210',
                              boxShadow: 'none',
                              color: '#fff',
                            }}
                            _active={{
                              bg: '#622210',
                              boxShadow: 'none',
                              color: '#fff',
                            }}
                            w="196px"
                            mx="auto"
                            fontWeight={500}
                          >
                            {navItem.title} <RightArrow fontSize="20px" />
                          </Link>
                          {/* </NextLink> */}
                        </Flex>
                      );
                    }
                  })}
                </Box>
              </Box>
            )}
          </Box>
        </Flex>
        <Box></Box>
        <HeaderStyle />
      </Flex>
    </Box>
  );
};

export default Header;
