/* Built In Imports */
// import NextLink from 'next/link';
import { useRouter } from 'next/router';

/* External Imports */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { BsChevronDown } from 'react-icons/bs';

/* Internal Imports */
/* Components */
import { isOpenInNewTab, refineUrlDomain } from '@ihs/components/Utility/utils';

/* Services */

/**
 *
 */
const LinkComponent = ({ title, href, mt, pl, ...props }) => {
  return (
    // <NextLink
    //     href={href}
    //     passHref
    //     legacyBehavior
    // >
    <Link
      href={href}
      _hover={{
        textDecoration: 'none',
        color: '#812C12',
      }}
      target={isOpenInNewTab(href) ? '_blank' : ''}
      _focus={{ boxShadow: 'none' }}
      {...props}
    >
      {/* <ListItem> */}
      <Text
        mt={mt || '25px'}
        pl={pl || '20px'}
        fontSize={{ md: '19px' }}
        lineHeight={{ md: '28px' }}
      >
        {title}
      </Text>
      {/* </ListItem> */}
    </Link>
    // </NextLink>
  );
};
export default LinkComponent;

export const LinkWChild = ({ href, children, ...props }) => {
  const router = useRouter();
  return (
    <Box
      borderBottom="1px solid #DADADA"
      fontWeight={router?.asPath === href ? 700 : 400}
    >
      {/* <NextLink
                href={href}
                passHref
                legacyBehavior
            > */}
      <Link
        href={href}
        _hover={{
          textDecoration: 'none',
          color: '#812C12',
        }}
        target={isOpenInNewTab(href) ? '_blank' : ''}
        _focus={{ boxShadow: 'none' }}
        lineHeight={{ md: '28px' }}
        fontSize={{ md: '19px' }}
        {...props}
      >
        {children}
      </Link>
      {/* </NextLink> */}
    </Box>
  );
};

export const HeaderAccBut = ({
  flex,
  title,
  color,
  fontWeight,
  navItem,
  ...props
}) => {
  return (
    <Box as="h2">
      <AccordionButton
        className="test"
        m="24px 0px 24px -15px"
        _focus={{ boxShadow: 'none' }}
        _active={{ color: '#812C12' }}
        _hover={{ color: '#812C12' }}
        py="0"
      >
        <Box
          as="span"
          flex={flex || '1'}
          fontSize={{ base: '20px' }}
          pl="20px"
          lineHeight={{ base: '28px' }}
          textAlign="left"
          {...props}
          // fontWeight={400}
          color={color}
          fontWeight={fontWeight || 400}
          _hover={{ color: '#812C12 !important', fontWeight: 700 }}
          _focus={{ boxShadow: 'none', fontWeight: 700 }}
          _active={{ color: '#812C12', fontWeight: 700 }}
        >
          <Link
            href={refineUrlDomain(navItem?.url)}
            _hover={{
              textDecoration: 'none',
            }}
          >
            {title}
          </Link>
        </Box>
        <BsChevronDown />
      </AccordionButton>
    </Box>
  );
};

export const MenuList = ({ menuIndex, menuList, setMenuIndex, urlMatch }) => {
  const router = useRouter();

  return (
    <>
      {menuList?.map((navItem, index) => {
        const indexNotToShow = [5, 6, 7];
        if (
          !navItem.key ||
          indexNotToShow.indexOf(index) > -1 ||
          navItem.key === 'admission-portal'
        ) {
          return null;
        } else if (navItem?.submenuItems?.length > 0) {
          return (
            <Box
              pl="2.5rem"
              pos="relative"
              key={nanoid()}
              fontSize={{ md: '18px', base: '18px' }}
            >
              <Link
                cursor="pointer"
                _hover={{
                  textDecoration: 'none',
                  color: '#622210',
                }}
                href={refineUrlDomain(navItem?.url)}
                _focus={{ boxShadow: 'none' }}
                _active={{
                  textDecoration: 'none',
                  color: '#622210',
                }}
                target={isOpenInNewTab(navItem?.url) ? '_blank' : ''}
                color={
                  urlMatch?.[0] === navItem?.title.toLowerCase() && '#622210'
                }
              >
                <Box
                  onMouseOver={() => {
                    setMenuIndex(index);
                  }}
                  onMouseOut={() => {
                    setMenuIndex(-1);
                  }}
                  className="mainMenu"
                  p="0 5px 12px 5px"
                  borderBottom={
                    urlMatch?.[0] === navItem?.key
                      ? '4px solid #622210'
                      : '4px solid transparent'
                  }
                  _hover={{ borderBottom: '4px solid #622210' }}
                  transition="border .2s ease-out"
                >
                  {navItem?.title}
                  <Text as="span" display="inline">
                    {menuIndex === index ? (
                      <ChevronUpIcon fontSize="20px" />
                    ) : (
                      <ChevronDownIcon fontSize="20px" />
                    )}
                  </Text>
                  <Box
                    pos="absolute"
                    w="max-content"
                    fontSize={{ md: '16px', base: '16px' }}
                    pt="16px"
                    ml={{ base: '0', md: '-5px' }}
                    // display={navItem?.submenuItem?.length ? "block" : "none"}
                  >
                    {navItem?.submenuItems?.length > 0 &&
                      menuIndex === index && (
                        <List
                          transition="all .2s linear"
                          color="#3B444B"
                          bg="#FFF"
                          p="0px 15px 25px"
                          minW="209px"
                          className="list"
                          w="100%"
                          filter="drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.10)) drop-shadow(0px 11px 11px rgba(0, 0, 0, 0.09)) drop-shadow(0px 25px 15px rgba(0, 0, 0, 0.05)) drop-shadow(0px 44px 18px rgba(0, 0, 0, 0.01)) drop-shadow(0px 69px 19px rgba(0, 0, 0, 0.00))"
                        >
                          {navItem?.submenuItems?.map(subItem => {
                            return (
                              <Link
                                href={refineUrlDomain(subItem?.url)}
                                target={
                                  isOpenInNewTab(subItem?.url) ? '_blank' : ''
                                }
                                key={subItem?.key}
                                _hover={{
                                  textDecoration: 'none',
                                  color: '#622210',
                                }}
                                _focus={{ boxShadow: 'none' }}
                              >
                                {/* <NextLink
                                                            // href={`isha-home-school${subItem?.url}`} 
                                                            href={refineUrlDomain(subItem?.url)}
                                                            key={subItem?.key}> */}
                                <ListItem pt="16px">{subItem?.title}</ListItem>
                                {/* </NextLink> */}
                              </Link>
                            );
                          })}
                        </List>
                      )}
                  </Box>
                </Box>
              </Link>
              {/* </NextLink> */}
            </Box>
          );
        } else {
          return (
            <Box
              pl="2.5rem"
              pos="relative"
              key={nanoid()}
              fontSize={{ md: '18px', base: '18px' }}
            >
              {/* <NextLink
                                href={refineUrlDomain(navItem?.url)}
                                passHref
                                legacyBehavior
                            > */}
              <Link
                _hover={{
                  textDecoration: 'none',
                  color: '#622210',
                }}
                _focus={{ boxShadow: 'none' }}
                _active={{
                  textDecoration: 'none',
                  color: '#622210',
                }}
                target={isOpenInNewTab(navItem?.url) ? '_blank' : ''}
                href={refineUrlDomain(navItem?.url)}
                color={navItem?.key === router.query?.levelOne && '#622210'}
              >
                <Box
                  className="mainMenu"
                  p="0 5px 12px 5px"
                  borderBottom={
                    router?.asPath === refineUrlDomain(navItem?.url)
                      ? '4px solid #622210'
                      : '4px solid transparent'
                  }
                  _hover={{ borderBottom: '4px solid #622210' }}
                  transition="border .2s ease-out"
                >
                  {navItem?.title}
                </Box>
              </Link>
              {/* </NextLink> */}
            </Box>
          );
        }
      })}
    </>
  );
};

export const HeaderAccItem = ({ navItem, color, fontWeight }) => {
  const router = useRouter();
  const accPanelSt = {
    background: 'transparent',
    pl: '40px',
    // mt: '-5px',
    _focus: { boxShadow: 'none' },
  };

  return (
    <AccordionItem border="none" borderBottom="1px solid #DADADA">
      <HeaderAccBut
        flex={1}
        title={navItem?.title}
        color={color}
        fontWeight={fontWeight}
        pl="30px"
        navItem={navItem}
      />
      <AccordionPanel
        pt="-10px"
        mt="-10px"
        paddingInlineEnd="24px"
        {...accPanelSt}
      >
        <List fontSize={{ base: '18px' }}>
          {navItem?.submenuItems?.map(submenu => {
            return (
              <Link
                href={refineUrlDomain(submenu?.url)}
                _hover={{
                  textDecoration: 'none',
                  color: '#812C12',
                  fontWeight: 700,
                }}
                _focus={{ boxShadow: 'none' }}
                target={isOpenInNewTab(submenu?.url) ? '_blank' : ''}
                key={submenu?.key || nanoid()}
              >
                {/* <NextLink
                                // href={`isha-home-school${submenu?.url}`}
                                href={refineUrlDomain(submenu?.url)}
                                passHref
                                legacyBehavior
                                key={submenu?.key || nanoid()}
                            > */}
                <ListItem
                  pt="5px"
                  fontWeight={
                    router?.asPath === `isha-home-school${navItem?.url}`
                      ? 700
                      : 400
                  }
                  _hover={{
                    textDecoration: 'none',
                    color: '#812C12',
                    fontWeight: 700,
                  }}
                  lineHeight="20px"
                  mb="10px"
                >
                  {submenu?.title}
                </ListItem>
                {/* </NextLink> */}
              </Link>
            );
          })}
        </List>
      </AccordionPanel>
    </AccordionItem>
  );
};
