/* Built in Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';

/* Internal Imports */

/**
 *
 */
export default function ScrollTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // scroll-to-top classes: fixed, bottom:0, right:0
  return (
    <>
      <Box>
        {isVisible && (
          <Flex
            justifyContent="center"
            alignItems="center"
            pos="fixed"
            w="48px"
            h="48px"
            right={{ base: '18px', md: '101px' }}
            bottom="65px"
            borderRadius="25px"
            bgColor="#023452"
            _hover={{ bg: '#622210' }}
            cursor="pointer"
            zIndex="10"
            onClick={scrollToTop}
          >
            <Icon
              width="24px"
              height="24px"
              viewBox="0 0 24px 24px"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.0001 5.58594L20.7072 14.293L19.293 15.7073L12.0001 8.41436L4.70718 15.7073L3.29297 14.293L12.0001 5.58594Z"
                fill="#FBAD18"
              />
            </Icon>
          </Flex>
        )}
      </Box>
    </>
  );
}
