
const HeaderStyle = () => {
  return (
    <>
      <span>
        <style global jsx>
          {`
          @media only screen and (max-width: 1200px) {
            .desktop {
              display: none !important;
            }
            .mobile {
              display: block !important;
            }
          }
          @media only screen and (min-width: 1200px) {
            .desktop {
              display: flex !important;
            }
            .mobile {
              display: none !important;
            }
          }
          }

          .list {
            display: none !important;
          }

          .list:hover {
            display: block !important;
            background-color: none !important;
          }

          .mainMenu:hover .list {
            display: block !important;
          }
          .downArrow {
            transition: all 200ms linear;
          }

          .sticky-menu {
            position: sticky;
            top: 0px;
            z-index: 100;
          }          
        `}
        </style>
      </span>
    </>
  )
}

export default HeaderStyle