/* Built In Imports */
import { useEffect, useRef } from 'react';

/* External Imports */
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';

/* Internal Imports */
/* Components */
import BuildTimeComment from '@components/Utility/BuildTimeComment';
import IhsLayout from '@ihs/components/Layout/Layout';
import AuthContextProvider from '@store/auth-context';
import { ishaTheme } from '../styles/theme/index';
import { mixPanel } from '@utils/Mixpanel';

/* Services */
/* Styles */
import('../styles/globals-ihs.css');

/**
 * @param Component
 * @param pageProps
 */

const ISOApp = ({ Component, pageProps }) => {
  const firstUpdate = useRef(true);

  const tagManagerArgs = {
    gtmId: 'GTM-5G2NJ8P',
  };

  useEffect(() => {

    /* Start Mixpanel  */
    const Mixpanel = mixPanel(
      window?.location.pathname,
      process?.env?.NEXT_PUBLIC_BUILDTYPE
    );

    console.log("window?.location.pathname");

    if (Object.keys(Mixpanel)?.length > 0) {
      const mixPanelPageViewObj = {
        page_name: window?.document.title,
      };
      // Track shivanga page views
      if (window?.location.pathname.startsWith('/isha-home-school')) {
        Mixpanel.track('homeschool_pageview', mixPanelPageViewObj);
      }
    }
    /* End Mixpanel  */

    setTimeout(function () {
      if (firstUpdate.current) {
        if (process.browser) {
          TagManager.initialize(tagManagerArgs);
        }
        firstUpdate.current = false;
      } else {
        // consoleLog('GTM pushed to data layer', window.location.href);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Pageview',
          url: window.location.href,
          title: window.document.title,
        });
      }
    }, 1500);
  }, [pageProps]);

  return (
    <AuthContextProvider>
      <ChakraProvider resetCSS theme={ishaTheme}>
        <IhsLayout>
          <Component {...pageProps} />
        </IhsLayout>
      </ChakraProvider>
      <BuildTimeComment />
    </AuthContextProvider>
  );
};

export default ISOApp;
