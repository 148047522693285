/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Footer from '@ihs/components/Navigation/Footer';
import Header from '@ihs/components/Navigation/Header';
import ScrollTop from '@ihs/components/Utility/ScrollTop';

/**
 * Renders the Layout component.
 *
 * @param {object} children - Child Elements.
 * @returns {ReactElement} Layout component
 */
export default function IhsLayout({ children }) {
  const {
    headerMenuData,
    footerData
  } = children.props;
  // console.log('IhsLayout', headerMenuData, footerData);

  return (
    <Box>
      <Header
        headerMenuData={headerMenuData}
      />
      {children}
      <Footer
        footerData={footerData}
      />
      <ScrollTop />
    </Box>
  );
}
